import { BILLION, MILLION } from 'constants/initialNumbers';

const THOUSAND = 1000;
const LOG_BASE = 3;
const PRECISION_DEFAULT = 1;
const COUNTRY_SUFFIXES = {
  DEFAULT: ['', '', 'M', 'B', 'T'],
  AR: ['', '', 'MM', 'Bi', 'T'],
};

const floorToFixedString = (number: number, precision: number): string => {
  const power = Math.pow(10, precision);
  return (Math.floor(number * power) / power).toFixed(precision);
};

const validateInputs = (value, precision) => {
  const isNumber = typeof value === 'number';
  const isFiniteValue = isFinite(value);
  const isPositive = value >= 0;
  const isPrecisionValid = typeof precision === 'number' && precision >= 0;

  return isNumber && !isNaN(value) && isFiniteValue && isPositive && isPrecisionValid;
};

export const calculateSuffix = (value, initialNumber = MILLION, country = 'US') => {
  const suffixes = COUNTRY_SUFFIXES[country] || COUNTRY_SUFFIXES.DEFAULT;
  const limit = initialNumber >= BILLION ? BILLION : MILLION;

  if (value >= limit) {
    const suffixIndex = Math.floor(Math.log10(value) / LOG_BASE);
    if (suffixIndex >= suffixes.length) {
      throw new Error('Number too large to abbreviate');
    }
    return suffixes[suffixIndex];
  }

  return '';
};

const formatNumber = (value, precision = PRECISION_DEFAULT) => {
  if (!validateInputs(value, precision)) {
    return '0';
  }

  const isLargeNumber = value >= MILLION;
  const adjustedValue = isLargeNumber
    ? value / Math.pow(THOUSAND, Math.floor(Math.log10(value) / LOG_BASE))
    : value;

  return isLargeNumber
    ? floorToFixedString(adjustedValue, precision).replace('.', ',')
    : adjustedValue.toString();
};

export const abbreviateNumber = ({
  value,
  precision = PRECISION_DEFAULT,
  initialNumber = MILLION,
  country = 'US',
}) => {
  try {
    const suffix = calculateSuffix(value, initialNumber, country);
    const formattedValue = formatNumber(value, precision);
    return { abbreviatedNumber: formattedValue, suffix };
  } catch (error) {
    return { abbreviatedNumber: '0', suffix: '' };
  }
};
