import {
  ConsolidatedData,
  ContentData,
  Contract,
  ContractsAndComponents,
  KpiCardData,
  RewardCardData,
  SummaryAndRewardComponents,
} from './formatReturnData.types';

export const formatUseGetContractByChainIdData = (
  contentArray: ContentData,
  country: string
): ConsolidatedData => {
  const consolidatedData = initializeConsolidatedData(contentArray);

  if (!contentArray || Object.keys(contentArray).length === 0) {
    return consolidatedData;
  }

  const countryName = country;
  const contentAccordingToCountry = filterObjectsByCountry(
    contentArray.ContractsAndComponents,
    countryName
  );
  const summaryAndRewardAccordingToCountry = contentArray.SummaryAndRewardComponents.filter(
    (obj) => obj.country === countryName
  );

  const contracts = summaryAndRewardAccordingToCountry.map((item) => ({
    contractId: item.physicalNumber,
    feature: item.feature,
    startDate: item.startDate,
    endDate: item.endDate,
  }));

  const summaryAcceptance = summaryAndRewardAccordingToCountry.some(
    (data) => data.summaryComponent.toggle.kpiAcceptance
  );

  ['growth', 'numeric', 'percentage', 'irs', 'ar'].forEach((type) => {
    consolidatedData[type + 'CardDataResponse'] = getKpiList(contentAccordingToCountry, type);
  });

  summaryAndRewardAccordingToCountry.forEach((data) => {
    updateRewardAndKpiCardData(consolidatedData, data, countryName);
    updateContractInfoData(consolidatedData, contracts as unknown as Array<Contract>);
  });

  updateDriverInfoData(consolidatedData, contentAccordingToCountry);
  updateFeatureAcceptance(consolidatedData, summaryAndRewardAccordingToCountry, summaryAcceptance);

  return consolidatedData;
};

export const filterObjectsByCountry = (
  contractAndComponents: Array<ContractsAndComponents>,
  countryAcronym: string
): Array<ContractsAndComponents> => {
  if (!contractAndComponents || !countryAcronym) {
    return [];
  }

  return contractAndComponents.filter(
    ({ contract: { driverWithProgressAmount } }) =>
      driverWithProgressAmount[0].country.acronym === countryAcronym
  );
};

const getKpiList = (contractsAndComponents: Array<ContractsAndComponents>, type: string) => {
  const list = [];

  if (contractsAndComponents) {
    contractsAndComponents.forEach((item) =>
      item.component.map((kpiItem) => {
        if (kpiItem.content.componentType === type) {
          const brands = item.contract.driverWithProgressAmount
            .filter((driver) => driver.id === kpiItem.content.kpiId)
            .map((driver) => driver.brands)
            .join(', ');

          list.push({ ...kpiItem, brands });
        }
      })
    );
  }

  return list;
};

export const hasAcceptedReward = (
  SummaryAndRewardComponents: Array<SummaryAndRewardComponents>
) => {
  if (SummaryAndRewardComponents.length !== 0) {
    const rewardsAccepted = SummaryAndRewardComponents.flatMap(
      ({
        rewardComponent: {
          toggle: { accepted },
        },
      }) => Object.values(accepted)
    );

    const hasUnAccepted = rewardsAccepted.some((reward) => reward === true);

    if (hasUnAccepted) {
      return true;
    }
  }
  return false;
};

const getProcessKpiData = (
  summaryAndRewardComponents: Array<SummaryAndRewardComponents>,
  feature: string
) => {
  const someKpiStarted = summaryAndRewardComponents
    .filter((item) => item.feature === feature)
    .map((item) => item.rewardComponent.toggle.isStarted);

  const allKpisAchieved = summaryAndRewardComponents
    .filter((item) => item.feature === feature)
    .map((item) => item.summaryComponent.content.kpiNotAchieved);

  const showToEarnValue = summaryAndRewardComponents
    .filter((item) => item.feature === feature)
    .map((item) => item.rewardComponent.toggle.showToEarnValue);

  const isAnyKpiStarted = someKpiStarted.some((obj) =>
    obj ? Object.values(obj).includes(true) : false
  );

  const allKpisAchievedResult = allKpisAchieved.every((number) => number === 0);

  return {
    isAnyKpiStarted,
    allKpisAchieved: allKpisAchievedResult,
    showToEarnValue: showToEarnValue[0],
  };
};

const initializeConsolidatedData = (contentArray: ContentData): ConsolidatedData => ({
  rewardCardDataResponse: {},
  kpiCardDataResponse: {},
  growthCardDataResponse: [],
  numericCardDataResponse: [],
  percentageCardDataResponse: [],
  irsCardDataResponse: [],
  arCardDataResponse: [],
  contractInfoDataResponse: {
    contracts: [],
    startDate: null,
    endDate: null,
    contractId: null,
    contractName: null,
    contractType: null,
    driverInfoData: [],
    chainId: null,
    accountId: null,
  },
  dateRangeListResponse: contentArray.dateRangeList,
});

const updateRewardAndKpiCardData = (
  consolidatedData: ConsolidatedData,
  data: SummaryAndRewardComponents,
  countryName: string
) => {
  const { feature } = data;

  if (!consolidatedData.rewardCardDataResponse[feature]) {
    consolidatedData.rewardCardDataResponse[feature] = initializeRewardCardData(
      countryName,
      feature
    );
  }
  if (!consolidatedData.kpiCardDataResponse[feature]) {
    consolidatedData.kpiCardDataResponse[feature] = initializeKpiCardData(feature);
  }

  const rewardContent = data.rewardComponent.content;
  const summaryContent = data.summaryComponent.content;

  const featureRewardData = consolidatedData.rewardCardDataResponse[feature];
  const featureKpiData = consolidatedData.kpiCardDataResponse[feature];

  featureRewardData.progress.lastProgressValue +=
    rewardContent.rewardProgress.lastTotalRewardsAchieved;
  if (data.rewardComponent.toggle.hasHistoryStatus) {
    featureRewardData.progress.historyStatus = data.rewardComponent.toggle.hasHistoryStatus;
  }
  featureRewardData.revenue.totalRewards += rewardContent.totalRewards;
  featureRewardData.revenue.rewardsToEarn += rewardContent.rewardsToEarn;

  featureKpiData.amountToAchieve += summaryContent.totalOfKpis - summaryContent.kpiAchieved;
  featureKpiData.amountAchievedLastMonth += summaryContent.kpiAchievedLastMonth;
  featureKpiData.amountAchieved += summaryContent.kpiAchieved;
  featureKpiData.kpiNotAchieved += summaryContent.kpiNotAchieved;
  featureKpiData.totalKpis += summaryContent.totalOfKpis;

  const endDate = new Date(data.endDate);
  const contractInfoData = consolidatedData.contractInfoDataResponse;
  if (!contractInfoData.endDate || endDate < new Date(contractInfoData.endDate)) {
    contractInfoData.startDate = data.startDate;
    contractInfoData.endDate = data.endDate;
    contractInfoData.contractId = data.physicalNumber;
    contractInfoData.contractName = data.chainName;
    contractInfoData.accountId = data.accountId;
    contractInfoData.contractType = data.feature;
    contractInfoData.chainId = data.chainId;
  }

  if (rewardContent.feature === 'ESCALAS') {
    featureRewardData.progress.progressValue += summaryContent.totalRewardEarned;
  } else {
    featureRewardData.progress.progressValue += rewardContent.rewardProgress.rewardsValueAchieved;
  }
};

const initializeRewardCardData = (countryName: string, feature: string): RewardCardData => ({
  accepted: false,
  locale: countryName,
  progress: { progressValue: 0, lastProgressValue: 0, historyStatus: false },
  revenue: { totalRewards: 0, rewardsToEarn: 0 },
  contractType: feature,
  isAnyKpiStarted: false,
  allKpisAchieved: false,
  toggles: { showToEarnValue: false },
});

const initializeKpiCardData = (feature: string): KpiCardData => ({
  accepted: false,
  amountToAchieve: 0,
  amountAchievedLastMonth: 0,
  amountAchieved: 0,
  kpiNotAchieved: 0,
  contractType: feature,
  totalKpis: 0,
  isAnyKpiStarted: false,
  allKpisAchieved: false,
});

const updateContractInfoData = (consolidatedData: ConsolidatedData, contracts: Array<Contract>) => {
  consolidatedData.contractInfoDataResponse.contracts = contracts;
};

const updateDriverInfoData = (
  consolidatedData: ConsolidatedData,
  contentAccordingToCountry: Array<ContractsAndComponents>
) => {
  contentAccordingToCountry.forEach((data) =>
    consolidatedData.contractInfoDataResponse.driverInfoData.push(
      ...data.contract.driverWithProgressAmount
    )
  );
};

const updateFeatureAcceptance = (
  consolidatedData: ConsolidatedData,
  summaryAndRewardAccordingToCountry: Array<SummaryAndRewardComponents>,
  summaryAcceptance: boolean
) => {
  Object.keys(consolidatedData.rewardCardDataResponse).forEach((feature) => {
    const featureData = consolidatedData.rewardCardDataResponse[feature];

    featureData.accepted = hasAcceptedReward(
      summaryAndRewardAccordingToCountry.filter((d) => d.feature === feature)
    );

    const { isAnyKpiStarted, allKpisAchieved, showToEarnValue } = getProcessKpiData(
      summaryAndRewardAccordingToCountry,
      feature
    );

    featureData.isAnyKpiStarted = isAnyKpiStarted;
    featureData.allKpisAchieved = allKpisAchieved;
    featureData.toggles.showToEarnValue = showToEarnValue;
  });

  Object.keys(consolidatedData.kpiCardDataResponse).forEach((feature) => {
    const featureData = consolidatedData.kpiCardDataResponse[feature];
    featureData.accepted = summaryAcceptance;

    const { isAnyKpiStarted, allKpisAchieved } = getProcessKpiData(
      summaryAndRewardAccordingToCountry,
      feature
    );

    featureData.isAnyKpiStarted = isAnyKpiStarted;
    featureData.allKpisAchieved = allKpisAchieved;
  });
};
