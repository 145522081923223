import { getMonthsOfYear } from '../formattedDate';
import { FormatMonthsToPeriodFilterArg, GetMonthsRange } from './getMonthsRange.types';

const generateRange = ({ range, start, end, year, months }) => {
  for (let i = start; i <= end; i++) {
    range.push({
      label: months[i],
      value: `${('00' + (i + 1)).slice(-2)}${year}`,
    });
  }
  return range;
};

export const getMonthsRange: GetMonthsRange = ({ startDate = '', endDate = '', locale }) => {
  try {
    const months = getMonthsOfYear(locale);
    const start = startDate.split(/-/);
    const end = endDate.split(/-/);
    const from = new Date(Number(start[0]), Number(start[1]) - 1, Number(start[2]));
    const to = new Date(Number(end[0]), Number(end[1]) - 1, Number(end[2]));
    const fromYear = from.getFullYear();
    const toYear = to.getFullYear();

    const fromMonth = from.getMonth();
    const toMonth = to.getMonth();

    let range = [];

    if (fromYear !== toYear) {
      range = generateRange({ range, start: fromMonth, end: 11, year: fromYear, months });
      range = generateRange({ range, start: 0, end: toMonth, year: toYear, months });
    } else {
      range = generateRange({ range: [], start: fromMonth, end: toMonth, year: fromYear, months });
    }

    return range;
  } catch (e) {
    return [];
  }
};

export const formatMonthsToPeriodFilter: FormatMonthsToPeriodFilterArg = ({
  locale,
  dateRangeList,
}) => {
  try {
    const months = getMonthsOfYear(locale);

    const range = [];
    dateRangeList.forEach((item: string) => {
      range.push({
        label: `${months[parseInt(item.substring(0, 2)) - 1].substring(0, 3)} ${item.substring(2)}`,
        value: `${item}`,
      });
    });

    return range;
  } catch (e) {
    return [];
  }
};
