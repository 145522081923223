import { get } from '@api';
import { TChainAndPocByUserResponse, TChainByUserResponse, TChainSearchResponse } from '@hooks';

export type TGetChains = {
  userId: string;
  pageNumber: number;
  pageSize: number;
  signal: AbortSignal;
  term?: string;
  country: string;
};

export const getChains = ({
  userId,
  pageNumber,
  pageSize,
  signal,
  country,
}: TGetChains): Promise<TChainByUserResponse> => {
  return get({
    url: `/api/link-commercial-kpis-service/stakeholder/chainsByUser?user_id=${userId}&page=${pageNumber}&size=${pageSize}`,
    config: {
      signal,
      headers: {
        country,
        userId,
      },
    },
  });
};

export const getChainsSearch = ({
  userId,
  pageNumber,
  pageSize,
  term,
  signal,
  country,
}: TGetChains): Promise<TChainSearchResponse> => {
  if (!term || term.length <= 2) {
    const error: any = new Error('Term length must be greater than 2');
    error.response = { status: 411 };
    throw error;
  }
  const request = get({
    url: `/api/link-commercial-kpis-service/chain/filter?page=${pageNumber}&size=${pageSize}&userId=${userId}&filterParam=${term}`,
    config: {
      signal,
      headers: {
        country,
        userId,
      },
    },
  });
  return request;
};

export const getChainsAndPocs = ({
  userId,
  pageNumber,
  pageSize,
  signal,
  country,
  term = '',
}: TGetChains): Promise<TChainAndPocByUserResponse> => {
  const maxInvalidTermsCount = 2;
  const page = !term || term.length <= maxInvalidTermsCount ? pageNumber : 0;

  return get({
    url: `/api/link-commercial-kpis-service/chain/chainsAndPocsByUserId?userId=${userId}&page=${page}&size=${pageSize}&filterParam=${term}`,
    config: {
      signal,
      headers: {
        country,
        userId,
      },
    },
  });
};
